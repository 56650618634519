<script lang="ts" setup>
import { useFormItem } from '@arco-design/web-vue';

defineOptions({
  name: 'OpenRouterButton',
});

const props = defineProps({
  to: {
    type: String,
    default: '',
  }
});

const { mergedDisabled } = useFormItem();
</script>

<template>
  <RouterLink class="open-router-button" :to="props.to" custom v-slot="{ href, navigate }" v-if="!mergedDisabled">
    <AButton :href="href" @click="navigate" v-bind="$attrs">
      <slot></slot>
    </AButton>
  </RouterLink>
  <AButton class="open-router-button" v-bind="$attrs" v-else>
    <slot></slot>
  </AButton>
</template>

<style lang="scss" scoped>
</style>
