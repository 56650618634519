import type { AxiosResponse, AxiosError, AxiosRequestHeaders } from 'axios';
import axios from 'axios';
import { Notification, Modal } from '@arco-design/web-vue';
import { type Ref } from 'vue';
import { getToken, isAuthCode } from '@/utils/auth';
import router from '@/router';
import { useUserStore } from '@/store';

export declare interface FetchOptions {
  method?: string,
  upload?: boolean,
  data?: any,
  json?: boolean,
  cache?: boolean,
  headers?: any,
  errorMessage?: string | boolean,
  loadingStatus?: Ref<boolean>,
  onUploadProgress?: (progress: any) => void,
  onUploadPercent?: (percent: number, progressEvent: any) => void,
};

export declare interface FetchResult<ResT> {
  isSuccess: boolean,
  code: number | string,
  msg?: string,
  data?: ResT,
  $response?: AxiosResponse | AxiosError | Error,
};

const DEFAULT_ERROR_CODE = 99999;

let isShowLogoutModal = false;

// 创建axios实例
const instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  timeout: 30000,
});

// request拦截器
instance.interceptors.request.use(config => {
  if (config.method === 'get') {
    config.params = config.data;
  }

  const token = getToken();

  if (token) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }
    config.headers['X-OPENATOM-TOKEN'] = token;
  }

  return config;
}, error => {
  // Do something with request error
  console.error(error); // for debug
  return Promise.reject(error);
});

export async function $fetch<ResT = any>(url: string, inputOptions: FetchOptions = {}): Promise<FetchResult<ResT>> {
  const options = { ...inputOptions };

  if (!options.method) {
    options.method = 'post';
  }

  if (!options.data) {
    options.data = {};
  }

  // 默认语言为 zh 通过路由的特殊参数识别当前语言
  if (!options.data.lc) {
    options.data.lc = router.currentRoute.value.query.__system_language_code__ || 'zh';
  }

  if (!options.headers) {
    options.headers = {};
  }

  if (options.upload === true) {
    const fd = new FormData();

    for (const key in options.data) {
      if (Object.prototype.hasOwnProperty.call(options.data, key)) {
        const value = options.data[key];

        if (value === undefined) {
          continue;
        }

        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (typeof item === 'object') {
              for (const subKey in item) {
                if (Object.prototype.hasOwnProperty.call(item, subKey)) {
                  fd.append(`${key}[${index}][${subKey}]`, item[subKey]);
                }
              }
            }
            else {
              fd.append(`${key}[]`, item);
            }
          });
        }
        else {
          fd.append(key, value);
        }
      }
    }

    options.data = fd;
  }
  else if (options.method === 'post') {
    if (options.json !== false) {
      options.headers['Content-Type'] = 'application/json; charset=utf-8';
    }
    else {
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
  }

  if (options.method.toLowerCase() === 'get' && options.cache !== true) {
    options.data._ = String(+new Date()) + String(Math.floor(Math.random() * 9999));
  }

  if (!options.onUploadProgress && options.onUploadPercent) {
    options.onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        options.onUploadPercent?.(Math.floor((progressEvent.loaded / progressEvent.total) * 100), progressEvent);
      }
    }
  }

  let loadingTimer;

  if (options.loadingStatus) {
    loadingTimer = setTimeout(() => {
      options.loadingStatus && (options.loadingStatus.value = true);
    }, 200);
  }

  let result: FetchResult<ResT>;

  try {
    const response = await instance(url, options);

    result = {
      isSuccess: response?.data?.success ?? true,
      code: response?.data?.code ?? 0,
      data: response?.data?.data,
      msg: response?.data?.message,
      $response: response,
    }
  }
  catch (error: any) {
    if (axios.isAxiosError(error)) {
      const response = error.response;

      result = {
        isSuccess: response?.data?.success ?? false,
        code: response?.data?.error?.code ?? DEFAULT_ERROR_CODE,
        data: response?.data?.error?.details,
        msg: response?.data?.error?.message || response?.data?.message || error.message,
        $response: response,
      }
    }
    else {
      result = {
        isSuccess: false,
        code: DEFAULT_ERROR_CODE,
        msg: error.message,
        $response: error,
      }
    }
  }

  if (options.loadingStatus) {
    clearTimeout(loadingTimer);
    options.loadingStatus.value = false;
  }

  if (!result.isSuccess) {
    if (isAuthCode(Number(result.code)) && url !== '/auth/v1/logout') {
      if (!isShowLogoutModal) {
        isShowLogoutModal = true;

        Modal.error({
          title: '登出',
          content: '你已被登出，请重新登录',
          okText: '重新登录',
          maskClosable: false,
          escToClose: false,
          closable: false,
          async onOk() {
            const userStore = useUserStore();

            await userStore.logout();
            window.location.reload();
          },
        });
      }
    }
    else {
      if (result.code === DEFAULT_ERROR_CODE) {
        Notification.error({
          content: `网络请求出错! API: ${url} ; 错误信息: ${result.msg}`,
          duration: 60 * 1000,
        });
      }
      else if (options.errorMessage !== false) {
        Notification.error({
          content: (options.errorMessage as string) || result.msg || '请求出错！请联系管理员或稍后再试！',
          duration: 60 * 1000,
        });
      }
    }
  }

  return result;
};
