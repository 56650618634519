import axios from 'axios';
import { Md5 } from 'ts-md5';
import { $fetch } from '@/utils/fetch';

export interface UploadOptions {
  onProgress?: (progress: number) => void;
};

const getHashFileName = (file: File) => new Promise<string | boolean>((resolve) => {
  const reader = new FileReader();

  reader.onload = (e: ProgressEvent<FileReader>) => {
    if (e.target?.result) {
      const md5 = new Md5();
      md5.appendByteArray(new Uint8Array(e.target.result as ArrayBuffer));

      const suffix = file.name.slice(file.name.lastIndexOf('.'));

      resolve(`${md5.end() as string}${suffix}`);
    }
    else {
      resolve(false);
    }
  }
  reader.onerror = () => resolve(false);

  reader.readAsArrayBuffer(file);
});

const getUploadSignature = async () => {
  const { isSuccess, data } = await $fetch('/oss/v1/credential');

  return { isSuccess, data };
};

const uploadFile = async (file: File, options: UploadOptions = {}) => {
  const { isSuccess, data } = await getUploadSignature();

  if (!isSuccess) {
    return {
      isSuccess: false,
      msg: '无权上传文件！',
    };
  }

  const md5Filename = await getHashFileName(file);

  if (!md5Filename) {
    return {
      isSuccess: false,
      msg: '上传文件名获取错误！',
    };
  }

  const formData = new FormData();

  formData.append('key', `${data.dir}${md5Filename}`);
  formData.append('OSSAccessKeyId', data.accessId);
  formData.append('policy', data.policy);
  formData.append('signature', data.signature);
  formData.append('success_action_status', '200');
  formData.append('file', file);

  try {
    const response = await axios.post(`https://${data.host}`, formData, {
      onUploadProgress: (e) => {
        if (e.total) {
          options.onProgress?.((e.loaded / e.total) * 100);
        }
        else {
          options.onProgress?.(100);
        }
      },
    });

    const status = response.status;

    if (status !== 200) {
      return {
        isSuccess: false,
        msg: '上传失败！',
      };
    }

    return {
      isSuccess: true,
      msg: '上传成功！',
      url: `${data.url}/${data.dir}${md5Filename}`,
    };
  }
  catch (error: any) {
    if (error.response) {
      return {
        isSuccess: false,
        msg: '网络错误: ' + error.response.status,
      };
    }
    else {
      return {
        isSuccess: false,
        msg: '图片上传错误: ' + error.message,
      };
    }
  }
};

export default function useUploader() {
  return {
    uploadFile,
    getUploadSignature,
    getHashFileName,
  };
}
